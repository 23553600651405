import React from 'react'
import classes from "./Approach.module.scss"
import img1 from "../../../assets/img/main/4/1.png"
import { Trans, useTranslation } from 'react-i18next'

const Approach = () => {
    const { t } = useTranslation("");
    return (
        <div className={[classes.approach, "mb"].join(" ")}>
            <div className={[classes.approachBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.approachContent, ""].join(" ")}>
                        <h2 className={[classes.approachContentTitle].join(" ")}>
                            <Trans>main_4_1</Trans>
                        </h2>
                        <ul className={[classes.approachContentList, ""].join(" ")}>
                            <li className={[classes.approachContentListItem, "font-16"].join(" ")}>
                                <Trans>main_4_3</Trans>
                            </li>
                            <li className={[classes.approachContentListItem, "font-16"].join(" ")}>
                                <Trans>main_4_4</Trans>
                            </li>
                            <li className={[classes.approachContentListItem, "font-16"].join(" ")}>
                                <Trans>main_4_5</Trans>
                            </li>
                            <li className={[classes.approachContentListItem, "font-16"].join(" ")}>
                                <Trans>main_4_6</Trans>
                            </li>
                        </ul>
                        <p className={[classes.approachContentText, "font-14"].join(" ")}>
                            <Trans>main_4_7</Trans>
                        </p>
                    </div>

                </div>
                <div className={[classes.approachMob, ""].join(" ")}>
                    <img src={img1} alt="Юридическая и правовая помощь" />
                </div>

            </div>
        </div>
    )
}

export default Approach