import React, { useState } from 'react'
import classes from "./Bottom.module.scss"
import img1 from "../../../assets/img/main/8/1.png"
import short1 from "../../../assets/img/main/img-short-1.jpg"
import short2 from "../../../assets/img/main/img-short-2.jpg"
import short3 from "../../../assets/img/main/img-short-3.jpg"
import short4 from "../../../assets/img/main/img-short-4.jpg"
import short5 from "../../../assets/img/main/img-short-5.jpg"
import short6 from "../../../assets/img/main/img-short-6.jpg"
import short7 from "../../../assets/img/main/img-short-7.jpg"
import short8 from "../../../assets/img/main/img-short-8.jpg"
import short9 from "../../../assets/img/main/img-short-9.jpg"
import short10 from "../../../assets/img/main/img-short-10.jpg"
import short11 from "../../../assets/img/main/img-short-11.jpg"
import short12 from "../../../assets/img/main/img-short-12.jpg"
import short13 from "../../../assets/img/main/img-short-13.jpg"
import short14 from "../../../assets/img/main/img-short-14.jpg"
import short15 from "../../../assets/img/main/img-short-15.jpg"
import short16 from "../../../assets/img/main/img-short-16.jpg"
import short17 from "../../../assets/img/main/img-short-17.jpg"
import short18 from "../../../assets/img/main/img-short-18.jpg"
import short19 from "../../../assets/img/main/img-short-19.jpg"
import short20 from "../../../assets/img/main/img-short-19.jpg"
import Button from '../../GlobalComponents/Button/Button'
import { useNavigate } from 'react-router-dom'
import axios from "axios";
import { Trans, useTranslation } from 'react-i18next'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import imgLeft from "../../../assets/img/main/5/left.png";
import imgRight from "../../../assets/img/main/5/right.png";
const Bottom = () => {
    const { t } = useTranslation("");
    const [check, setCheck] = useState(false)
    const [checkValid, setCheckValid] = useState(true)
    const [name, setName] = useState("")
    const [nameValid, setNameValid] = useState(true)
    const [phone, setPhone] = useState("")
    const [phoneValid, setPhoneValid] = useState(true)
    const navigate = useNavigate();
    const [swiper, setSwiper] = useState({});

    async function send() {
        const messageData = {
            siteName: window.location.hostname,
            name,
            phone,
        };
        try {
            const result = await axios.post('https://civbt.xyz/api/bot/SDPCDBN9OP', messageData);
            if (result.status === 200) {
                navigate("/thankyou")
                return true;
            } else {
                alert('Something went wrong. Try again');
                return false;
            }
        } catch (error) {
            if (error.response.data.status === 403) {
                alert(error.response.data.message);

                return;
            }
            alert(error.message);
        }

    }


    function valid() {
        let letsGo = true
        if (!check) {
            setCheckValid(false)
            letsGo = false
        } else {
            setCheckValid(true)
        }
        if (name.length < 2) {
            setNameValid(false)
            letsGo = false
        } else {
            setNameValid(true)
        }
        if (phone.length < 2) {
            setPhoneValid(false)
            letsGo = false
        } else {
            setPhoneValid(true)
        }
        return letsGo
    }

    return (
        <div className={[classes.block, "mt mb"].join(" ")} id='mainForm'>
            <div className={[classes.blockBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.videoReviewsBlock, ""].join(" ")}>
                        <div className={[classes.blockRowLeft, ""].join(" ")}>
                            <h2 className={[classes.videoReviewsTitle].join(" ")}>
                                Video reviews:
                            </h2>
                            <div className={[classes.videoReviews, ""].join(" ")}>
                                <Swiper
                                    spaceBetween={50}
                                    slidesPerView={3}
                                    onSlideChange={() => console.log('slide change')}
                                    onSwiper={(swiper) => {
                                        console.log(swiper)
                                        setSwiper(swiper)
                                    }}
                                >
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short17}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtu.be/VcvbqzopQ-w"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short16}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtu.be/PmJkZVezO2k?si=vnM7j6ChIQW9gQ97"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://www.youtube.com/shorts/0rMe6WjrH1w"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtu.be/YD6F2mZZdAs"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/DonsXk7Yico?si=oF-QkQGAJTlMBfb5"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtu.be/rPfS2GjGeKg"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtu.be/3O07rCm6_DI"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/fZW18rLKc5A"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/2IiexPwE994"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/XSllj79T5cY"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/vqcbBAteGbw"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtu.be/OwxJxWCLwlI"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtu.be/15542yN5qIQ"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtu.be/bW-K26eXj7E"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtu.be/YZ5vuXfUySs"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtu.be/zH5LQu3xtJ8"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/pl-QEQJSxdY"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/YZXJWfw4J8Y"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/n4hqo8UOLsE"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtu.be/bG66bik_CLU"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtu.be/bHe81badL6Q"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/3m8SdrVAXSo"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtu.be/OQV6Rl3ioaM"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtu.be/7gky2MXCraQ"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtu.be/nww6OgDAGMI"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/IMcfEEVp1eg"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/fzmz3Hnq4g0"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/0M9G9K6uFB4"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/VtSS7z4Zzwg"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/N3YdWKvu9ec"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short15}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/CDeogQ6kXGw"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short19}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/zQYhtowHta0?feature=share"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short19}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/Gy71lQpUewQ?feature=share"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short19}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/4SS_nXzizZk?feature=share"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short19}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtu.be/Y3tFoQ4PaQE"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short14}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/BkSP2ZaCZi0?si=yI5m2Xy_l2lZ-hxz"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short13}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/y79nknDonZM?si=OpMy1j657ZCvbH92"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short12}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/WTY7a2nODXk?si=QkWgSHrRSC-lZqnd"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short11}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/vHBjEjwhSqU?si=JROGSJdp58I-j7Tn"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short19}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/e2yJN6CC39c?si=OUBWd8qi40WOYstw"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short18}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/SIxhtGOOejU?si=NjJbfP_zluiazAqg"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short10}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/Za4czX7stPg?si=ViH3M3ocGfF1Phw8"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short9}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/9mPZYdzOyHA?si=IUWdT1PKvnZmlyLh"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short8}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/quJnToOhuGA?si=8b7JWjCNFUeYo5_G"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short7}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/9VLryuM5WQo?si=yhxsONYPiPM4vcIo"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short6}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/jmheY7dSK1U?si=g97kMQXNDmjnCkS-"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short5}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/e57q9CgRcWs?si=E45m5_CbrhC3Mqnz"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short4}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/AvuG-dYli24?si=yqVf2fQRQ3onG78c"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short3}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/Cj2Rt62NRGg?si=QzaZcoeLvVqsdwP-"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short2}
                                                 alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/A_KB2lOpD_I?si=7Fhm0udYae5xPY42"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short1} alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://youtube.com/shorts/LkDVGZZxnUI?si=8ivrhJ_fLt2HPSkP"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className={[classes.videoReviewsItem, ""].join(" ")}>
                                            <img className={[classes.videoReviewsItemPreview, ""].join(" ")}
                                                 src={short20} alt=""/>
                                            <a className={[classes.videoReviewsItemPlay, ""].join(" ")}
                                               href="https://www.youtube.com/watch?v=FjG0FEUieVU"
                                               target="_blank"></a>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>


                            </div>
                            <footer>
                                <div className={[classes.blockRowLeftBottom, ""].join(" ")}>
                                    <div className={[classes.blockRowLeftBottomLeft, ""].join(" ")}
                                         onClick={() => {
                                             swiper.slidePrev()
                                         }}
                                    >
                                        <img src={imgLeft} alt=""/>
                                    </div>
                                    <div className={[classes.blockRowLeftBottomRight, ""].join(" ")}
                                         onClick={() => {
                                             swiper.slideNext()
                                         }}
                                    >
                                        <img src={imgRight} alt=""/>
                                    </div>
                                </div>
                            </footer>
                        </div>
                    </div>
                    <div className={[classes.blockRow, ""].join(" ")}>
                        <div className={[classes.blockRowLeft, ""].join(" ")}>
                            <h2 className={[classes.blockRowLeftTitle].join(" ")}>
                                <Trans>main_2_1_title</Trans>
                            </h2>
                            <b className={[classes.blockRowLeftSubtitle].join(" ")}>
                                <Trans>main_2_1_subtitle</Trans>
                            </b>
                            <p className={[classes.blockRowLeftText, "font-20"].join(" ")}>
                                <Trans>main_2_1_descr</Trans>
                            </p>
                            <div className={[classes.blockRowLeftForm, ""].join(" ")}>
                            <div className={nameValid ? [classes.blockRowLeftFormInput, ""].join(" ") : [classes.blockRowLeftFormInput, classes.error, ""].join(" ")}>
                                    <input type="text" placeholder={t("main_8_left_2")} className='font-16' value={name} onChange={(e) => {
                                        setName(e.target.value)
                                        valid()
                                    }} />
                                </div>
                                <div className={phoneValid ? [classes.blockRowLeftFormInput, ""].join(" ") : [classes.blockRowLeftFormInput, classes.error, ""].join(" ")}>
                                    <input type="text" placeholder={t("main_8_left_3")} className='font-16' value={phone} onChange={(e) => {
                                        setPhone(e.target.value)
                                        valid()
                                    }} />
                                </div>
                                <div
                                    onClick={() => {
                                        setCheck(!check)
                                        valid()
                                    }}
                                    className={check ? [classes.blockRowLeftFormCheckout, classes.active, "font-14"].join(" ") : checkValid ? [classes.blockRowLeftFormCheckout, "font-14"].join(" ") : [classes.blockRowLeftFormCheckout, classes.error, "font-14"].join(" ")}>
                                    <span></span>
                                    <Trans>main_8_left_4</Trans>
                                </div>
                                <div className={[classes.blockRowLeftFormBtn, ""].join(" ")}
                                    onClick={() => {
                                        let res = valid()
                                        if (res) {
                                            send()
                                        } else {
                                            console.log("error")
                                        }
                                    }}
                                >
                                    <Button login={"true"} dark={true}><Trans>btn_submit</Trans></Button>
                                </div>

                            </div>
                        </div>
                        <div className={[classes.blockRowRight, ""].join(" ")}>
                            <div className={[classes.blockRowRightContent, ""].join(" ")}>
                                <h3 className={[classes.blockRowRightContentTitle, "font-40"].join(" ")}>
                                    <Trans>main_8_right_1</Trans>
                                </h3>
                                <p className={[classes.blockRowRightContentSubTitle].join(" ")}>
                                    <Trans>main_8_right_2</Trans>
                                </p>
                                <p className={[classes.blockRowRightContentLocation, classes.mail, "font-14"].join(" ")}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none">
                                        <path
                                            d="M19.6626 5C19.9321 5 20.1526 5.22051 20.1526 5.49005V5.55207C20.1526 5.82161 19.9703 6.16616 19.7475 6.31776L11.9772 12.0929C11.7544 12.2445 11.3896 12.2445 11.1667 12.093L3.40529 6.32642C3.18242 6.17482 3 5.83037 3 5.56083V5.49015C3 5.22061 3.22051 5.0001 3.49005 5.0001H19.6626V5Z"
                                            fill="#021848"/>
                                        <path
                                            d="M11.1766 13.7163C11.3941 13.8756 11.7499 13.8757 11.9675 13.7165L19.7572 8.01613C19.9748 7.85695 20.1526 7.94722 20.1526 8.21676V18.5097C20.1526 18.7792 19.9321 18.9997 19.6626 18.9997H3.49005C3.22051 18.9997 3 18.7792 3 18.5097V8.21676C3 7.94722 3.17789 7.85705 3.39535 8.01633L11.1766 13.7163Z"
                                            fill="#021848"/>
                                    </svg>
                                    <a href="mailto:support@finance-fintech.com" className="font-14">
                                        support@finance-fintech.com
                                    </a>
                                </p>
                                <p className={[classes.blockRowRightContentLocation, "font-14"].join(" ")}>
                                    <img src={img1} alt=""/>
                                    <Trans>main_8_right_3</Trans>
                                </p>
                                <div className={[classes.blockRowRightContentBottom, ""].join(" ")}>
                                    <div className={[classes.blockRowRightContentBottomItem, ""].join(" ")}>
                                        <p className='font-12'><Trans>main_8_right_4</Trans></p>
                                        <p className='font-12'><Trans>main_8_right_5</Trans></p>
                                    </div>
                                    <div className={[classes.blockRowRightContentBottomItem, ""].join(" ")}>
                                        <p className='font-12'><Trans>PVM</Trans></p>
                                        <p className='font-12'><Trans>LT100005742818</Trans></p>
                                    </div>
                                    <div className={[classes.blockRowRightContentBottomItem, ""].join(" ")}>
                                        <p className='font-12'><Trans>main_8_right_6</Trans></p>
                                        <p className='font-12'><Trans>main_8_right_7</Trans></p>
                                    </div>
                                </div>
                                <div className={[classes.blockRowRightContentBottom, ""].join(" ")} style={{marginTop: 20}}>
                                    <div className={[classes.blockRowRightContentBottomItem, ""].join(" ")}>
                                        <a className='font-12' href="/docs/Terms_of_Use.pdf" download>{t("terms")}</a>
                                    </div>
                                    <div className={[classes.blockRowRightContentBottomItem, ""].join(" ")}>
                                        <a className='font-12' href="/docs/Privacy_Policy.pdf" download>{t("privacy")}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bottom
