import React from 'react'
import classes from "./Banner.module.scss"
import img1 from "../../../assets/img/main/1/1.png"
import img1Mob from "../../../assets/img/main/1/mob.png"
import Button from "../../GlobalComponents/Button/Button"
import { Trans, useTranslation } from 'react-i18next'
const Banner = () => {

    const { t } = useTranslation("");

    return (
        <div className={[classes.banner, ""].join(" ")}>
            <div className={[classes.bannerBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.bannerContent, ""].join(" ")}>
                        <h1 className={[classes.bannerTitle, "font-64"].join(" ")}>
                            Fintech Finance
                        </h1>
                        <p className={[classes.bannerText, "font-20"].join(" ")}>
                            <Trans>main_1_2</Trans>
                        </p>
                        <div className={[classes.bannerBtn, ""].join(" ")}>
                            <Button><Trans>btn_consultation</Trans></Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner